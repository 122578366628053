import React from "react";

import { Row, Col, Dropdown } from "react-bootstrap";

import "../styles/mapPopup.css";
import { fieldNames } from "../utilities/constants";

const {
  DISTRICT_REPRESENTED,
  LEGISLATIVE_CONFERENCE,
  IMAGE,
  TITLE,
  FIRST_NAME,
  LAST_NAME,
  CHAIRPERSONSHIP,
  IN_OFFICE_SINCE,
  DISTRICT_OFFICE_ADDRESS,
  DISTRICT_OFFICE_PHONE,
  DISTRICT_OFFICE_FAX,
  CAPITOL_OFFICE_ADDRESS,
  CAPITOL_OFFICE_PHONE,
  CAPITOL_OFFICE_FAX,
  EMAIL,
  WEBSITE,
  SEXUAL_ORIENTATION,
  RACE,
  GENDER,
  PARTY,
  MAP_IMAGE,
  VOTING_AGE_POP,
  REGISTERED_VOTERS,
  POC_REGISTRATION,
  TURNOUT_2019,
  TURNOUT_2020,
  TURNOUT_2021,
  OTHER_OFFICE_ADDRESS,
  OTHER_OFFICE_PHONE,
} = fieldNames;

function MapPopup(props) {
  const legislatorImageAltText = `${props.rep[TITLE]} ${props.rep[FIRST_NAME]} ${props.rep[LAST_NAME]} headshot`;
  const districtImageAltText = `district ${props.rep[DISTRICT_REPRESENTED]} shape`;
  let party = props.rep[PARTY].join(", ");

  let chairpersonship = props.chairpersonship
    ? `Chair of Committee(s): ${props.rep[CHAIRPERSONSHIP]}`
    : null;

  let map_image = props.rep[MAP_IMAGE][0].url ? (
    <img
      src={props.rep[MAP_IMAGE][0].url}
      className="map-image"
      alt={districtImageAltText}
    />
  ) : null;

  let districtOffice =
    !props.rep[DISTRICT_OFFICE_ADDRESS] &&
    !props.rep[DISTRICT_OFFICE_PHONE] &&
    !props.rep[DISTRICT_OFFICE_FAX]
      ? "hide-district-office"
      : "district-office";

  let legislativeOffice =
    !props.rep[CAPITOL_OFFICE_ADDRESS] &&
    !props.rep[CAPITOL_OFFICE_PHONE] &&
    !props.rep[CAPITOL_OFFICE_FAX]
      ? "hide-legislative-office"
      : "legislative-office";

  let otherContactInfo =
    !props.rep[OTHER_OFFICE_ADDRESS] && !props.rep[OTHER_OFFICE_PHONE]
      ? "hide-other-office"
      : "other-office";

  let nycetData =
    !props.rep[VOTING_AGE_POP] &&
    !props.rep[REGISTERED_VOTERS] &&
    !props.rep[POC_REGISTRATION] &&
    !props.rep[TURNOUT_2019] &&
    !props.rep[TURNOUT_2020] &&
    !props.rep[TURNOUT_2021]
      ? "hide-nycet-data"
      : "nycet-data";

  const roundValue = (value) => {
    return Math.round(value * 100);
  };

  let turnout2019 = roundValue(props.rep[TURNOUT_2019]);
  let turnout2020 = roundValue(props.rep[TURNOUT_2020]);
  let turnout2021 = roundValue(props.rep[TURNOUT_2021]);
  let nonWhiteRegVoters = roundValue(props.rep[POC_REGISTRATION]);
  let votingAgePop = parseInt(props.rep[VOTING_AGE_POP]).toLocaleString();
  let regVoters = parseInt(props.rep[REGISTERED_VOTERS]).toLocaleString();

  let officialWebsite = props.rep[WEBSITE] ? (
    <a
      href={props.rep[WEBSITE]}
      target="_blank"
      className="official-website"
      rel="noreferrer"
    >
      Official Website
    </a>
  ) : null;

  let renderRace = () => {
    let race = props.rep[RACE];
    if (race === "Unknown") {
      race = " ";
    }
    return race;
  };

  return props.rep[LAST_NAME] === "Vacant" ? (
    <div className="vacant-person">
      <Row>
        <Col xs={3}>
          <img
            src={props.rep[IMAGE][0].url}
            className="person-img"
            alt="legislator placeholder headshot"
          />
        </Col>
        <Col xs={9}>
          <p className="full-name">
            {props.rep[FIRST_NAME]} {props.rep[LAST_NAME]}
          </p>
        </Col>
      </Row>

      <Row className="district-area">
        <Col xs={3}>{map_image}</Col>
        <Col xs={9}>
          <p className="district-num">
            District {props.rep[DISTRICT_REPRESENTED]}
          </p>
        </Col>
      </Row>
    </div>
  ) : (
    <div className="map-popup-container">
      <Row>
        <Col xs={3}>
          <img
            src={props.rep[IMAGE][0].url}
            className="person-img"
            alt={legislatorImageAltText}
          />
        </Col>
        <Col xs={9}>
          <p className="title-and-in-office-since">
            {props.rep[TITLE]} Since {props.rep[IN_OFFICE_SINCE]}
          </p>
          <p className="full-name">
            {props.rep[FIRST_NAME]} {props.rep[LAST_NAME]} ({party})
          </p>
          <p>{props.rep[LEGISLATIVE_CONFERENCE]}</p>

          {<p>{chairpersonship}</p>}
        </Col>
      </Row>
      <Row className="district-area">
        <Col xs={3}>{map_image}</Col>
        <Col xs={9}>
          <p className="district-num">
            District {props.rep[DISTRICT_REPRESENTED]}
          </p>
        </Col>
      </Row>
      <Dropdown.Divider className="divider" />
      <Row>
        <Col xs={6}>
          <div className={districtOffice}>
            <p className="district-off-title">District Office</p>
            <p className="district-off-addr">
              {props.rep[DISTRICT_OFFICE_ADDRESS]}
            </p>

            <p>
              Tel:{" "}
              <span className="district-off-tel">
                {props.rep[DISTRICT_OFFICE_PHONE]}
              </span>
            </p>
            <p>
              F:{" "}
              <span className="district-off-fax">
                {props.rep[DISTRICT_OFFICE_FAX]}
              </span>
            </p>
          </div>

          <div className={legislativeOffice}>
            <p className="legislative-off-title">Legislative Office</p>
            <p className="legislative-off-addr">
              {props.rep[CAPITOL_OFFICE_ADDRESS]}
            </p>
            <p>
              Tel:{" "}
              <span className="legislative-off-tel">
                {props.rep[CAPITOL_OFFICE_PHONE]}
              </span>
            </p>
            <p>
              F:{" "}
              <span className="legislative-off-fax">
                {props.rep[CAPITOL_OFFICE_FAX]}
              </span>
            </p>
          </div>
          <div className={otherContactInfo}>
            <p className="legislative-off-title">Other Contact Info</p>
            <p className="legislative-off-addr">
              {props.rep[OTHER_OFFICE_ADDRESS]}
            </p>
            <p>
              Tel:{" "}
              <span className="legislative-off-tel">
                {props.rep[OTHER_OFFICE_PHONE]}
              </span>
            </p>
          </div>
        </Col>

        <Col xs={6}>
          <div className="personal-info">
            <p className="email-title">Email: </p>
            <p>
              <a className="email" href={"mailto:" + props.rep[EMAIL]}>
                {props.rep[EMAIL]}
              </a>
            </p>
            {officialWebsite}
            <div className="identity-info">
              Seems to identify as{" "}
              <span>
                {renderRace()}{" "}
                {props.rep[SEXUAL_ORIENTATION] === "Unknown"
                  ? " "
                  : props.rep[SEXUAL_ORIENTATION]}{" "}
                {props.rep[GENDER] === "Unknown" ? " " : props.rep[GENDER]}
              </span>
            </div>
          </div>
        </Col>
      </Row>
      <div className={nycetData}>
        <p className="nycet-data-heading">
          Data provided by{" "}
          <a
            href="https://www.nycet.org/"
            target="_blank"
            className="bold-text"
            rel="noreferrer"
          >
            New York Civic Engagement Table
          </a>
        </p>
        <Row>
          <Col xs={6}>
            <p>
              Voting Age Pop: <span className="bold-text">{votingAgePop}</span>
            </p>
            <p>
              Registered Voters: <span className="bold-text">{regVoters}</span>
            </p>
            <p>
              Non-White Reg Voters:{" "}
              <span className="bold-text">{nonWhiteRegVoters}%</span>
            </p>
          </Col>

          <Col xs={6}>
            <p>
              {" "}
              Turnout Rate 2019:{" "}
              <span className="bold-text">{turnout2019}%</span>
            </p>
            <p>
              {" "}
              Turnout Rate 2020:{" "}
              <span className="bold-text">{turnout2020}%</span>
            </p>
            <p>
              {" "}
              Turnout Rate 2021:{" "}
              <span className="bold-text">{turnout2021}%</span>
            </p>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default MapPopup;
