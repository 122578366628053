import React from "react";

import _ from "lodash";
import { Alert } from "react-bs-notifier";

import { fieldNames } from "../utilities/constants";

const {
  RACE,
  LEGISLATIVE_CONFERENCE,
  GENDER,
  COMMITTEE_ASSIGNMENTS_AND_CAUCUSES,
  REGION,
} = fieldNames;

const utils = {
  showNotice: function Notice() {
    return (
      <Alert type="warning">
        Need a list of legislators, their staff, and their contact info? Log in
        to
        <a
          href="https://mytools.advocacy-institute.org/"
          className="alert-link"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          My Tools{" "}
        </a>
        and go to Find Legislators. If you would like Advocacy Institute to
        generate a list for you, click
        <a
          href="https://airtable.com/shr49NADdD9nvYPwD"
          className="alert-link"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          here{" "}
        </a>
        to submit a request.
        <br />
        <br />
        To see a preview of the Senate and Assembly post-election, click
        <a
          href="https://drive.google.com/file/d/1e9UcGwb0g14lwKsVvwhKChhUK0F0qnWA/view"
          className="alert-link"
          target="_blank"
          rel="noreferrer"
          alt="Navigate to list of Senate and Assembly post election in english"
        >
          {" "}
          here.{" "}
        </a>
        For Spanish click{" "}
        <a
          href="https://drive.google.com/file/d/1RwqpuLAzvp825d0OsOKlmTdbfg9h5zES/view"
          className="alert-link"
          target="_blank"
          rel="noreferrer"
          alt="Navigate to list of Senate and Assembly post election in spanish"
        >
          here.
        </a>
      </Alert>
    );
  },

  sortByRaceAndName: function (people) {
    let sortedPeople = [];
    const byRace = _.sortBy(people, function (person) {
      return person.fields[RACE];
    });
    const group = _.groupBy(byRace, function (person) {
      return person.fields[RACE];
    });

    Object.keys(group).forEach((key) => {
      sortedPeople.push(_.sortBy(group[key], `fields["Last Name"]`));
    });
    return _.flatten(sortedPeople);
  },

  sortByRacePartyAndName: function (people) {
    let sortedParties = [];

    const byRace = _.sortBy(people, function (person) {
      return person.fields[RACE];
    });
    const group = _.groupBy(byRace, function (person) {
      return person.fields[RACE];
    });

    //sort party group by race and last name
    Object.keys(group).forEach((key) => {
      sortedParties.push(
        _.sortBy(
          group[key],
          `fields["Legislative Conference"]`,
          `fields["Last Name"]`
        )
      );
    });

    return _.flatten(sortedParties);
  },

  setCommitteeOptions: function (committees) {
    const committeeOptions = committees
      .sort()
      .reduce(function (committeeObjects, c) {
        let committee = Object.assign({ value: c }, { label: c });
        committeeObjects.push(committee);
        return committeeObjects;
      }, []);
    return committeeOptions;
  },

  renderLegend: function ({ legendCategories, people, title }) {
    return legendCategories.map((category) => {
      let legendCount = getLegendCount({
        people: people,
        category: category,
      });

      if (legendCount > 0) {
        return (
          <div key={category.id} className="legend-lbl">
            <div className="round-button">
              <div className={getClasses(category.id)} />
            </div>
            <h6 className="lblname">
              {category.party === "Undecided"
                ? `Pending Election Outcome ${legendCount}`
                : category.party === "Unknown"
                ? `Vacant Position ${legendCount}`
                : `${category.race} ${category.party} ${title} ${legendCount}`}
            </h6>
          </div>
        );
      }
    });
  },

  updateCounts: function ({
    peopleByGroup,
    genderFilter,
    raceFilter,
    regionFilter = null,
    committeeFilter,
  }) {
    const byGender = genderCount(peopleByGroup, genderFilter);
    const byRace = raceCount(peopleByGroup, raceFilter);
    const byRegion = regionCount(peopleByGroup, regionFilter);
    const byCommittee = committeeCount(peopleByGroup, committeeFilter);
    return _.intersection(byRace, byGender, byRegion, byCommittee).length;
  },

  trimRegion: function (regionStr) {
    let regions = regionStr.split(",");
    return regions.map((region) => {
      return region.trim().slice(0, -17);
    }); // trim off whitespace and "State EDC Region"
  },
};

function getClasses(value) {
  return `round-button-circle ${value}`;
}

function getLegendCount({ people, category }) {
  /*
   * category is an object from the array of legendCategories in constants.js
   * eg. { race: "Latino/a", party: "Republican", id: "latinx-republican" }
   */
  const party = category.party;
  const race = category.race;

  // first filter out just the people in the party we're looking at
  const peopleByParty = people.filter((person) => {
    if (race === "Race Unknown") {
      /*
       * we only want to show "Race Unknown" dots for legislators who have been elected.
       * if [LEGISLATIVE_CONFERENCE] includes "Undecided", it means a person has not
       * yet been elected to that seat and we should not show a "Race Unknown" dot for them.
       */
      return !person.fields[LEGISLATIVE_CONFERENCE].includes("Undecided");
    } else {
      return (
        person.fields[LEGISLATIVE_CONFERENCE] &&
        person.fields[LEGISLATIVE_CONFERENCE].includes(party)
      );
    }
  });

  // now filter by race
  const filteredPeople = peopleByParty.filter((person) => {
    if (race === "") {
      // Undecided seats have race = "", so want to return these without checking for race.
      return true;
    } else if (person.fields[RACE]) {
      return person.fields[RACE].includes(race);
    } else {
      return peopleByParty;
    }
  });
  return filteredPeople.length;
}

function genderCount(peopleByGroup, genderFilter) {
  if (genderFilter.length > 0) {
    return peopleByGroup.filter((rep) => {
      return genderFilter.includes(rep.fields[GENDER][0]);
    });
  } else {
    return peopleByGroup;
  }
}

function raceCount(peopleByGroup, raceFilter) {
  if (raceFilter.length > 0) {
    let filteredPeople = [];
    peopleByGroup.forEach((person) => {
      person.fields[RACE].forEach((race) => {
        if (raceFilter.includes(race)) {
          filteredPeople.push(person);
        }
      });
    });
    return filteredPeople;
  } else {
    return peopleByGroup;
  }
}

function regionCount(peopleByGroup, regionFilter) {
  if (regionFilter) {
    return peopleByGroup.filter((rep) => {
      const regions = utils.trimRegion(rep.fields[REGION]);
      let includesRegion = false;
      regions.forEach((region) => {
        if (regionFilter.includes(region)) {
          includesRegion = true;
        }
      });
      return includesRegion;
    });
  } else {
    return peopleByGroup;
  }
}

function committeeCount(peopleByGroup, committeeFilter) {
  if (committeeFilter.length > 0) {
    return peopleByGroup.filter((person) => {
      const repCommitteesList =
        person.fields[COMMITTEE_ASSIGNMENTS_AND_CAUCUSES];
      return (
        committeeFilter.filter((committee) => {
          return (
            repCommitteesList &&
            repCommitteesList.find((repCommittee) => repCommittee === committee)
          );
        }).length > 0
      );
    });
  } else {
    return peopleByGroup;
  }
}

export default utils;
