import React, { Component } from "react";

import _ from "lodash";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import Select from "react-select";
import { bindActionCreators } from "redux";

import LegislativeView from "./legislativeView";
import { fetchLegislators, addCouncilMembers } from "../utilities/apiWrapper";
import {
  legendCategories,
  genderOptions,
  raceOptions,
  fieldNames,
} from "../utilities/constants";
import utils from "../utilities/utils";

const { COMMITTEE_ASSIGNMENTS_AND_CAUCUSES, BOROUGH } = fieldNames;

class NycCouncil extends Component {
  constructor(props) {
    super(props);
    this.state = {
      genderFilter: "",
      raceFilter: "",
      committeeFilter: [],
    };

    this.chooseGenderFilter = this.chooseGenderFilter.bind(this);
    this.chooseRaceFilter = this.chooseRaceFilter.bind(this);
    this.chooseCommitteeFilter = this.chooseCommitteeFilter.bind(this);
    this.boroGroupCounts = this.boroGroupCounts.bind(this);
  }

  componentDidMount() {
    if (this.props.people.length === 0) {
      this.props.fetchLegislatorsAction({
        governmentBody: this.props.governmentBody,
        addLegislatorAction: addCouncilMembers,
      });
    }
  }

  chooseGenderFilter(eventKey) {
    this.setState({
      genderFilter: eventKey,
    });
  }

  chooseRaceFilter(eventKey) {
    this.setState({
      raceFilter: eventKey,
    });
  }

  chooseCommitteeFilter(eventKey) {
    let values = [];
    eventKey.forEach((choice) => {
      values.push(choice.value);
    });
    this.setState({
      committeeFilter: values,
    });
  }

  boroGroupCounts(borough) {
    if (
      !this.state.genderFilter &&
      !this.state.raceFilter &&
      !this.state.committeeFilter
    ) {
      return this.props[`${borough}`].length;
    } else {
      return utils.updateCounts({
        peopleByGroup: this.props[`${borough}`],
        genderFilter: this.state.genderFilter,
        raceFilter: this.state.raceFilter,
        committeeFilter: this.state.committeeFilter,
      });
    }
  }

  render() {
    let brooklynCount = this.boroGroupCounts("brooklyn");
    let queensCount = this.boroGroupCounts("queens");
    let manhattanCount = this.boroGroupCounts("manhattan");
    let bronxCount = this.boroGroupCounts("bronx");
    let statenCount = this.boroGroupCounts("staten");
    return (
      <div className="houses">
        <div className="house-components">
          {utils.showNotice()}
          <Select
            value={this.state.genderFilter}
            multi={true}
            placeholder="Filter by Gender..."
            simpleValue={true}
            options={genderOptions}
            onChange={this.chooseGenderFilter}
          />
          <Select
            value={this.state.raceFilter}
            multi={true}
            placeholder="Filter by Race..."
            simpleValue={true}
            options={raceOptions}
            onChange={this.chooseRaceFilter}
          />
          {this.props.showCommitteeFilter && (
            <Select
              value={this.state.committeeFilter}
              multi={true}
              placeholder="Filter by Committee..."
              options={utils.setCommitteeOptions(this.props.committeeOptions)}
              onChange={this.chooseCommitteeFilter}
            />
          )}
          <LegislativeView
            governmentBody={this.props.governmentBody}
            groups={{
              Brooklyn: this.props.brooklyn,
              Queens: this.props.queens,
              Manhattan: this.props.manhattan,
              Bronx: this.props.bronx,
              "Staten Island": this.props.staten,
            }}
            groupCount={{
              Brooklyn: brooklynCount,
              Queens: queensCount,
              Manhattan: manhattanCount,
              Bronx: bronxCount,
              "Staten Island": statenCount,
            }}
            sm={[12, 12, 12, 12, 12]}
            md={[3, 3, 2, 2, 2]}
            lg={[3, 3, 2, 2, 2]}
            genderFilter={this.state.genderFilter}
            raceFilter={this.state.raceFilter}
            committeeFilter={this.state.committeeFilter}
            legendCategories={legendCategories}
          />
          <Row>
            <div className="legend">
              <h4>Legend</h4>
              {utils.renderLegend({
                legendCategories: legendCategories,
                people: this.props.people,
                title: "Councilmembers",
              })}
            </div>
          </Row>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const people = _.get(state, ["council"], []);
  const brooklyn = people.filter((person) => {
    return person.fields[BOROUGH].includes("Brooklyn");
  });
  const queens = people.filter((person) => {
    return person.fields[BOROUGH].includes("Queens");
  });
  const manhattan = people.filter((person) => {
    return person.fields[BOROUGH].includes("Manhattan");
  });
  const bronx = people.filter((person) => {
    return person.fields[BOROUGH].includes("Bronx");
  });
  const staten = people.filter((person) => {
    return person.fields[BOROUGH].includes("Staten Island");
  });

  return {
    people: utils.sortByRacePartyAndName(people),
    brooklyn: utils.sortByRacePartyAndName(brooklyn),
    queens: utils.sortByRacePartyAndName(queens),
    manhattan: utils.sortByRacePartyAndName(manhattan),
    bronx: utils.sortByRacePartyAndName(bronx),
    staten: utils.sortByRacePartyAndName(staten),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchLegislatorsAction: bindActionCreators(fetchLegislators, dispatch),
  };
}

const componentCreator = connect(mapStateToProps, mapDispatchToProps);
export default componentCreator(NycCouncil);
