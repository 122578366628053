import { fieldNames } from "./constants";
import { isError } from "../actions/errorActions";
import { isLoading } from "../actions/loadingActions";

const { PUBLISH, PREVIEW } = fieldNames;

const base_id = process.env.REACT_APP_AIRTABLE_BASE_ID;
const personal_access_token =
  process.env.REACT_APP_AIRTABLE_PERSONAL_ACCESS_TOKEN;

export function fetchLegislators({
  governmentBody,
  offset = null,
  addLegislatorAction,
}) {
  return function (dispatch) {
    dispatch(isLoading(true));

    let fetchUrl = `https://api.airtable.com/v0/${base_id}/People?view=${governmentBody}`;
    if (offset) {
      fetchUrl += `&offset=${offset}`;
    }

    return fetch(fetchUrl, {
      method: "get",
      headers: {
        Authorization: `Bearer ${personal_access_token}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((people) => {
        const publishedLegislators = people.records.filter((person) => {
          return person.fields[PUBLISH];
        });

        const previewLegislators = people.records.filter((person) => {
          return person.fields[PREVIEW];
        });

        if (governmentBody.includes("preview")) {
          dispatch(addLegislatorAction(previewLegislators));
        } else {
          dispatch(addLegislatorAction(publishedLegislators));
        }

        dispatch(isError(false));

        const offset = people.offset; // Airtable will include this if there are more records to fetch
        if (offset) {
          dispatch(
            fetchLegislators({ governmentBody, offset, addLegislatorAction })
          );
        } else {
          dispatch(isError(false));
          dispatch(isLoading(false));
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(isLoading(false));
        dispatch(isError(true));
      });
  };
}

export function addSenators(senators) {
  return {
    type: "FETCH_SENATE",
    payload: senators,
  };
}

export function addAssemblymembers(assemblymembers) {
  return {
    type: "FETCH_ASSEMBLY",
    payload: assemblymembers,
  };
}

export function addCouncilMembers(councilmembers) {
  return {
    type: "FETCH_COUNCIL",
    payload: councilmembers,
  };
}

export function addNassauLeg(legislators) {
  return {
    type: "FETCH_NASSAU",
    payload: legislators,
  };
}

export function addSuffolkLeg(legislators) {
  return {
    type: "FETCH_SUFFOLK",
    payload: legislators,
  };
}

export function addDutchessLeg(legislators) {
  return {
    type: "FETCH_DUTCHESS",
    payload: legislators,
  };
}

export function addOrangeLeg(legislators) {
  return {
    type: "FETCH_ORANGE",
    payload: legislators,
  };
}

export function addPutnamLeg(legislators) {
  return {
    type: "FETCH_PUTNAM",
    payload: legislators,
  };
}

export function addUlsterLeg(legislators) {
  return {
    type: "FETCH_ULSTER",
    payload: legislators,
  };
}

export function addWestchesterLeg(legislators) {
  return {
    type: "FETCH_WESTCHESTER",
    payload: legislators,
  };
}

export function addBuffaloLeg(legislators) {
  return {
    type: "FETCH_BUFFALO",
    payload: legislators,
  };
}

export function addErieLeg(legislators) {
  return {
    type: "FETCH_ERIE",
    payload: legislators,
  };
}

export function addRochesterLeg(legislators) {
  return {
    type: "FETCH_ROCHESTER",
    payload: legislators,
  };
}

export function addMonroeLeg(legislators) {
  return {
    type: "FETCH_MONROE",
    payload: legislators,
  };
}
