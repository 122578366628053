// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-popup-container {
    font-family: Helvetica Neue, Arial, sans-serif;
    height: 400px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 10px 15px;
}

.map-popup-container p {
    margin: 0px;
}

.person-img {
    width: 60px;
    height: 60px;
}

.dropdown-divider.divider {
    margin: 10px 0;
    color: #888;

}

.title-and-in-office-since {
    font-weight: 200;
}

.full-name {
    font-weight: 600;
}

.district-area {
    margin-top: 10px;
}

.district-num {
    font-weight: 600;
}

.map-image {
    width: 60px;
    height: 60px;
}

.district-off-title, .legislative-off-title {
    text-decoration: underline;
}

.district-office, .legislative-office {
    margin-bottom: 15px;
}

.district-off-addr, .district-off-tel, .district-off-fax, .legislative-off-addr, .legislative-off-tel, .legislative-off-fax, .email, .official-website, .identity-info span {
    font-weight: 600;
}


.email {
    word-wrap: break-word;
}

.identity-info {
    margin-top: 20px;
}

.hide-district-office, .hide-legislative-office, .hide-other-office {
    display: none;
}

.district-office, .legislative-office, .other-office {
    display: block;
}

.bold-text {
    font-weight: bold;
}

.vacant-person {
    padding: 10px 15px;
    width: 301px;
}`, "",{"version":3,"sources":["webpack://./src/styles/mapPopup.css"],"names":[],"mappings":"AAAA;IACI,8CAA8C;IAC9C,aAAa;IACb,kBAAkB;IAClB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,WAAW;;AAEf;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;;AAGA;IACI,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,YAAY;AAChB","sourcesContent":[".map-popup-container {\n    font-family: Helvetica Neue, Arial, sans-serif;\n    height: 400px;\n    overflow-y: scroll;\n    overflow-x: hidden;\n    padding: 10px 15px;\n}\n\n.map-popup-container p {\n    margin: 0px;\n}\n\n.person-img {\n    width: 60px;\n    height: 60px;\n}\n\n.dropdown-divider.divider {\n    margin: 10px 0;\n    color: #888;\n\n}\n\n.title-and-in-office-since {\n    font-weight: 200;\n}\n\n.full-name {\n    font-weight: 600;\n}\n\n.district-area {\n    margin-top: 10px;\n}\n\n.district-num {\n    font-weight: 600;\n}\n\n.map-image {\n    width: 60px;\n    height: 60px;\n}\n\n.district-off-title, .legislative-off-title {\n    text-decoration: underline;\n}\n\n.district-office, .legislative-office {\n    margin-bottom: 15px;\n}\n\n.district-off-addr, .district-off-tel, .district-off-fax, .legislative-off-addr, .legislative-off-tel, .legislative-off-fax, .email, .official-website, .identity-info span {\n    font-weight: 600;\n}\n\n\n.email {\n    word-wrap: break-word;\n}\n\n.identity-info {\n    margin-top: 20px;\n}\n\n.hide-district-office, .hide-legislative-office, .hide-other-office {\n    display: none;\n}\n\n.district-office, .legislative-office, .other-office {\n    display: block;\n}\n\n.bold-text {\n    font-weight: bold;\n}\n\n.vacant-person {\n    padding: 10px 15px;\n    width: 301px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
