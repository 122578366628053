import React from "react";

import { Modal, Button, Row, Col, Container } from "react-bootstrap";

import { fieldNames } from "../utilities/constants";

const { FIRST_NAME, LAST_NAME, DISTRICT_REPRESENTED, IMAGE, MAP_IMAGE } =
  fieldNames;

function vacantPopup(props) {
  return (
    <Modal
      size="lg"
      centered
      show={props.show}
      onHide={props.onHide}
      className="person-container"
    >
      <Modal.Header closeButton>
        <Container className="person-info">
          <Row>
            {props.fields[IMAGE] && (
              <Col xs={3}>
                <img
                  className="modal-image"
                  src={props.fields[IMAGE][0].url}
                  width={100}
                  height={100}
                  alt="legislator placeholder headshot"
                />
              </Col>
            )}
            <Col xs={8} sm={9}>
              <Row>
                <h4 className="full-name-title">
                  {props.fields[FIRST_NAME] +
                    " " +
                    props.fields[LAST_NAME] +
                    " "}
                </h4>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={3}>
              <img
                src={props.fields[MAP_IMAGE][0].url}
                className="district-image"
                alt={
                  "district " + props.fields[DISTRICT_REPRESENTED] + " shape"
                }
              />
            </Col>
            <Col xs={8} sm={9}>
              <h5 className="district-num">
                District {props.fields[DISTRICT_REPRESENTED]}
              </h5>
            </Col>
          </Row>
          <div className="modal-close-btn">
            <Button onClick={props.onClick}>Close</Button>
          </div>
        </Container>
      </Modal.Header>
    </Modal>
  );
}
export default vacantPopup;
